import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthUserContext from '../../session/context'
import FirebaseContext from '../../firebase/context'

const Header = ({ handleOpen, handleRemove, openClass }) => {
  const [scroll, setScroll] = useState(0)
  const firebaseContext = useContext(FirebaseContext)
  const { user } = useContext(AuthUserContext)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 50
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })

  const clickSignOut = (event) => {
    event.preventDefault()
    firebaseContext.doSignOut()
  }

  return (
    <>
      <header className={'header sticky-bar stick'}>
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <a href="/" className="d-flex">
                <div className="header-logo">
                  <img alt="findAJobUSA" src="/assets/imgs/template/logo.svg" />
                </div>
              </a>
            </div>
            <div className="header-nav">
              <nav className="nav-main-menu">
                <ul className="main-menu">
                  <li>
                    <Link to="/jobs">Find a Job</Link>
                  </li>

                  <li>
                    <Link to="/pricing">Pricing Plans</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                </ul>
              </nav>
              <div
                className={`burger-icon burger-icon-white ${openClass && 'burger-close'}`}
                onClick={() => {
                  handleOpen()
                  handleRemove()
                }}
              >
                <span className="burger-icon-top" />
                <span className="burger-icon-mid" />
                <span className="burger-icon-bottom" />
              </div>
            </div>
            <div className="header-right">
              <div className="d-flex block-signin flex-nowrap ">
                <Link to="/job" className="btn btn-default btn-shadow hover-up">
                  Post a Job
                </Link>
                {!user && (
                  <Link
                    to="/login"
                    className="btn btn-grey btn-shadow ml-20 hover-up"
                  >
                    Sign in
                  </Link>
                )}
                {!user && (
                  <Link
                    to="/register"
                    className="text-link-bd-btom ml-20 hover-up"
                  >
                    Register
                  </Link>
                )}
                {user && (
                  <Link
                    to="/profile"
                    className="btn btn-grey btn-shadow ml-20 hover-up"
                  >
                    My Account
                  </Link>
                )}

                {user && (
                  <div className="ml-30 color-brand-2 header-name">
                    <a href="/profile">
                      <p>{user?.name}</p>
                    </a>
                    <a href="/profile?tab=3" className="color-brand-2">
                      <strong>{user?.credit} Credits</strong>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div style={{ height: '4.5rem' }} />

      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" />
                  <i className="fi-rr-search" />
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                {/* mobile menu start*/}
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li>
                      <Link to="/jobs">Find a Job</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing Plans</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      aria-label="SignOut"
                      onClick={clickSignOut}
                    >
                      Sign Out
                    </button>
                  </li>
                </ul>
              </div>
              <div className="site-copyright">
                Copyright 2022 ©FindaJobUSA.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" />
                  <i className="fi-rr-search" />
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                {/* mobile menu start*/}
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li>
                      <Link to="/jobs">Find a Job</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing Plans</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      aria-label="SignOut"
                      onClick={clickSignOut}
                    >
                      Sign Out
                    </button>
                  </li>
                </ul>
              </div>
              <div className="site-copyright">
                Copyright 2022 © JobBox. <br />
                Designed by AliThemes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
