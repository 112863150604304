import { Link } from 'react-router-dom'
import moment from 'moment'

const JobCard = ({ job, editable }) => {
  const date = moment(job.createdAt ? job.createdAt.toDate() : null).format(
    'MM/DD/YYYY',
  )
  const route = editable ? `/job/${job.id}` : `/jobs/${job.id}`
  return (
    <Link to={route} className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div className="card-grid-2 hover-up">
        <div className="card-grid-2-image-left">
          {!editable && <span className="flash" />}
          <div className="right-info text-start ellipsis-text">
            {editable && (
              <div className="status d-flex mr-10">
                <img
                  className="mr-4"
                  src="/assets/imgs/template/icons/System.png"
                />
                <div className="color-text-paragraph font-sm">
                  <b>
                    {job.status !== undefined
                      ? job.status.toUpperCase()
                      : 'PENDING'}
                  </b>
                </div>
              </div>
            )}

            <div className="name-job ellipsis-text">{job.company}</div>
            <span className="location-small">
              {job.city}, {job.state}
            </span>
          </div>
        </div>

        <div className="card-block-info">
          <div>
            <h6 className="ellipsis-text">{job.title}</h6>
          </div>
          <div className="mt-5">
            {/*<span className="card-briefcase">{job.contract}</span>*/}
            {date !== '' && <span className="card-time">{date}</span>}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: job.description }}
            className="card-description"
          />

          <div className="card-2-bottom mt-30">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="btn btn-default">
                  {editable ? 'Edit Job' : 'Learn More'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default JobCard
