import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer mt-50">
      <div className="footer-content pt-50 ptb-50">
        <div className="container" style={{ borderTop: '1px solid #e0e6f7' }}>
          <div className="row mt-20">
            <div className="footer-col-1 col-md-3 col-sm-12">
              <h4 className="mb-20 text-brand-1">FindaJobUSA</h4>
              <div className="mt-20 mb-20 font-xs text-paragraph-2">
                FindaJobUSA is one of the leading employer networks in the
                United States. We have over 10,000 job postings and continue to
                work closely with employers to make sure they get the support
                they need.
              </div>
            </div>
            <div className="footer-col-2 col-md-2 col-xs-6">
              <h6 className="mb-20">Employers</h6>
              <ul className="menu-footer">
                <li>
                  <Link to="/login">Register/Login</Link>
                </li>
                <li>
                  <Link to="/job">Post a Job</Link>
                </li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col-3 col-md-2 col-xs-6">
              <h6 className="mb-20">Job Seekers</h6>
              <ul className="menu-footer">
                <li>
                  <Link to="/jobs">Browse Jobs</Link>
                </li>
                <li>
                  <Link to="/register">Sign Up</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col-4 col-md-2 col-xs-6">
              <h6 className="mb-20">Quick links</h6>
              <ul className="menu-footer">
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container" style={{ borderTop: '1px solid #e0e6f7' }}>
          <div className="row mt-20">
            <div className="col-md-6 text-start">
              <span className="font-xs color-text-paragraph">
                Copyright © 2024. FIndAJobUSA
              </span>
            </div>
            <div className="col-md-6 text-md-end text-start">
              <div className="footer-social">
                <a
                  className="font-xs color-text-paragraph"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>
                <a
                  className="font-xs color-text-paragraph mr-30 ml-30"
                  href="/terms-of-use"
                >
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
