import { Collections } from '../constants/defines'
import _ from 'lodash'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore'

const getPlans = (db) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, Collections.PLANS)      
    )

    return getDocs(q)
      .then((querySnapshot) => {
        let items = []

        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id })
        })
        resolve(items)
      })
      .catch((error) => {
        console.log('getPlans: ', error)
        reject()
      })
  })
}

export { getPlans }
