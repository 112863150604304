// requires environment variable
const GCP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID

const Collections = {
  USERS: 'Users',
  JOBS: 'Jobs',
  ACTIVITIES: 'Activities',
  PLANS: 'Plans',
  PAYMENTS: 'Payments',
}

const Documents = {
  INDUSTRY_COUNTERS: 'Database/IndustryCounters',
}

const projectSettings = {
  'findajobusa-13ec3': {
    defaultBucket: 'findajobusa-13ec3.appspot.com',
    apiEndpoint: 'https://us-central1-findajobusa-13ec3.cloudfunctions.net/api',
    // apiEndpoint: 'http://127.0.0.1:5002/findajobusa-13ec3/us-central1/api',
    title: 'FindaJob',
  },
}

const apiSendMessage = `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/sendMessage`
const apiPurchasePlan = `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/purchasePlan`

const ShowTypes = [
  { value: 12, label: '12' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const SortTypes = [
  { value: 0, label: 'Newest Post' },
  { value: 1, label: 'Oldest Post' },
]
const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export {
  GCP_PROJECT_ID,
  Collections,
  projectSettings,
  apiSendMessage,
  apiPurchasePlan,
  ShowTypes,
  SortTypes,
  USA_STATES,
  Documents,
}
