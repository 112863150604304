import { Link } from 'react-router-dom'
import Layout from '../components/Layout/Layout'

export default function PaymentComplete() {
  return (
    <>
      <Layout>
        <div>
          <section className="section-box">
            <div className="breacrumb-cover">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="mb-10">Thank you</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-box mt-90">
            <div className="container">
              <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                <h3 className="text-center mb-55 wow animate__animated animate__fadeInUp">
                  Transaction complete - post your job below.
                </h3>
                <Link to="/job">
                  <button
                    className="btn btn-brand-1 hover-up w-50"
                    name="gologin"
                  >
                    Continue...
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
