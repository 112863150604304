import Layout from '../../components/Layout/Layout'

const TermsAndConditionsPage = () => {
  return (
    <Layout>
      <section className="section-box terms-of-use">
        <div className="container" style={{ textAlign: 'left' }}>
          <br />
          <br />
          <h4 style={{ textAlign: 'center' }}>Terms of Use </h4>
          <br />
          <p>
            Welcome to the website and services of USA Job Solutions LLC (“
            <strong>USA Job Solutions</strong>”, “<strong>we</strong>”, “
            <strong>our</strong>”, or "<strong>us</strong>"). These Terms of Use
            (this “<strong>Agreement</strong>” or “<strong>Terms of Use</strong>
            ”) is a legal contract between USA Job Solutions LLC and yourself
            (referenced herein with “<strong>you</strong>” or with “
            <strong>your</strong>”), which governs your use of and/or access to
            our Services (defined below).
          </p>
          <br />
          <p>
            This Agreement applies to all persons and entities who visit, use,
            or access any of the Services (“<strong>Users</strong>”). By
            accessing or using the Services, you signify that you have read,
            understood and agree to be bound by the terms and conditions of this
            Agreement, whether or not you are a registered user or customer of
            the Services.
          </p>
          <br />
          <p>
            We reserve the right to amend this Agreement at any time by positing
            an updated version of these Terms of Use (or any portion thereof) on
            the Site (defined below). Changes will be binding on you upon the
            earlier of (i) any access or use of the Services by you after the
            date the updated Terms of Use are posted or (ii) thirty (30) days
            after the modifications are posted. Any use of the Site or Services
            after the modi fications have become effective will be considered
            acceptance by you of the updated Terms of U se. If you do not agree
            to any of the terms of this Agreement, you are not permitted to use
            or access (or continue to access), the Services.
          </p>
          <br />
          <p>
            <strong>
              THIS AGREEMENT INCLUDES A CLASS ACTION WAIVER, WHICH MEANS THAT
              YOU AGREE TO PROCEED WITH ANY DISPUTE INDIVIDUALLY AND NOT AS PART
              OF A CLASS ACTION. DO NOT ACCESS OR USE ANY OF THE SERVICES IF YOU
              DO NOT AGREE TO THESE TERMS IN THEIR ENTIRETY.
            </strong>
          </p>
          <p>
            This Agreement applies to your interactions with USA Job Solutions
            through the following mechanisms, which are collectively referred to
            as the “<strong>Services</strong>”:
          </p>
          <br />
          <ul>
            <li>
              On USA Job Solutions website and your use thereof (currently
              located at findajobusa.net (collectively “
              <strong>Website(s)</strong>” or “<strong>Site(s)</strong>”).
            </li>
            <li>
              Through online communications between you and USA Job Solutions.
            </li>
            <li>
              Use of any other services and offerings that may be provided by
              USA Job Solutions.
            </li>
          </ul>
          <br />
          <h5>Type of Users:</h5>
          <br />
          <ul>
            <li>
              Users that use the Services to search for prospective employees,
              and/or post and/or distribute job openings (“
              <strong>Job Advertisements</strong>”), on behalf of themselves
              and/or a third party are also referred to in this Agreement as an
              “<strong>Employer</strong>”. If you use the Services on behalf of
              an employer, organization, agency, institution, or other entity,
              then (i) the term "you" includes both you and that entity, and
              (ii) you represent and warrant that you are an authorized
              representative of the entity with the full authority to bind the
              entity to this Agreement, and that you agree to this Agreement on
              the entity's behalf.
            </li>
            <li>
              If you use the Services to search for or apply to Job
              Advertisements, we refer to you as, a “<strong>Job Seeker</strong>
              ”.
            </li>
          </ul>
          <br />
          <h5>1. USE OF THE SERVICES</h5>
          <br />
          <p>
            1.1. <strong>Eligibility</strong>. You must be at least eighteen
            (18) years of age or the age of majority in the jurisdiction in
            which you reside to use the Services, so that you can form a binding
            contract with USA Job Solutions. If you are under the age of
            eighteen (18) or the age of majority, and you are permitted to work
            in the jurisdiction in which you reside, you represent that a parent
            or legal guardian has reviewed and agrees to this Agreement on your
            behalf. You may not use the Services if your use of the Services has
            been previously terminated or suspended by us, unless we have
            provided you with specific written authorization to re-use the
            Services.
          </p>
          <br />
          <p>
            1.2. <strong>Access Rights</strong>. We hereby grant you a limited,
            revocable, non-exclusive, non-sublicensable, and non-transferable
            license to access and use the Services solely: (i) with respect to
            Job Seekers: for your personal use seeking employment opportunities
            for yourself, and (ii) with respect to Employers: for your internal
            business purposes of seeking candidates for employment.
          </p>
          <br />
          <p>
            1.3. <strong>Availability</strong>. You are responsible for
            obtaining all services and technologies necessary to access to the
            Services.
          </p>
          <br />
          <p>
            1.4. <strong>Account</strong>. For certain Users, you may create an
            account with USA Job Solutions. When creating your account or
            uploading information to the Services through your account, you
            represent and warrant that you will provide accurate and complete
            information. If you have an account with USA Job Solutions, you are
            responsible for maintaining the confidentiality of all your
            usernames and passwords. You agree (i) not to allow a third party to
            use your account, usernames, or passwords at any time, and (ii) to
            notify us promptly of any actual or reasonably suspected
            unauthorized use of or access to your account, usernames, or
            passwords, or any other breach or suspected breach of this Agreement
            of which you become aware. You are responsible for all acts and
            omissions of your users, and for all activities that occur under
            your account. We reserve the right at any time, to disable or
            terminate your account, any username, password, or other identifier,
            whether chosen by you or provided by us, in our sole discretion, for
            any le gal reason, including any violation of any provision of this
            Agreement.
          </p>
          <br />
          <p>
            1.5. <strong>Limitations</strong>. You agree not to engage in any of
            the following: (i) copying, distributing, or disclosing any part of
            the Services in any medium, including without limitation by any
            automated or non -automated "scraping"; (ii) using any automated
            system, including without limitation "robots," "spiders," "offline
            readers," etc., to access the Services in a manner that sends more
            request messages to the USA Job Services servers than a human can
            reasonably produce in the same period of time by using a
            conventional on-line web browser; (iii) transmitting spam, chain
            letters, or other unsolicited communications; (iv) attempting to
            interfere with, compromise the system integrity or security or
            decipher any transmissions to or from the servers running the
            Services; (v) taking any action that imposes, or may impose, as
            determined in our sole discretion, an unreasonable or
            disproportionately large load on our infrastructure; (vi) uploading
            invalid data, viruses, worms, or other software agents through the
            Services; (vii) collecting or harvesting any personally identifiable
            information from the Services, except as expressly permitted by the
            Services; (viii) using the Services for any commercial solicitation
            purposes; (ix) impersonating another person or otherwise
            misrepresenting your affiliation with a person or entity, conducting
            fraud, hiding or attempting to hide your identity; (x) interfering
            with the proper working of the Services; (xi) accessing any content
            on the Services through any technology or means other than those
            provided or authorized by the Services; (xii) bypassing the measures
            we may use to prevent or restrict access to the Services, including
            without limitation, features that prevent or restrict use or copying
            of any content or enforce limitations on use of the Services or the
            content therein; (xiii) disclosing or sharing login credentials;
            (xiv) access the Services to build a competitive product or service;
            (xv) infringing any of USA Job Solutions intellectual property
            rights; (xvi) creating derivative works of the Services, reverse
            engineering or decompiling any parts of the Services; (xvii) framing
            or linking to any information or content on the Services; (xviii)
            posting or submitting any inaccurate, incomplete, or false
            biographical information or another person’s information; or (xiv)
            posting or submitting any material that is unlawful, illegal,
            defamatory, offensive, discriminatory, threatening, or obscene as
            determined by USA Job Solutions.
          </p>
          <br />
          <p>
            1.6. <strong>Service Changes</strong>. We may, without prior notice,
            change any aspect of the Services or stop providing the Services or
            features of the Services. If any such change, cessation or
            limitation of the Services materially and adversely affects you and
            was not due to your breach of this Agreement, then you may terminate
            your account on written notice to us provide, and we may provide a
            pro-rata refund of any prepaid fees pertaining to Services paid for,
            but not provided. We may also permanently or temporarily limit,
            condition, terminate or suspend your access to the Services or any
            features thereof, including if in our sole determination (acting
            reasonably) you breach or violate any provision of this Agreement,
            commit fraud or other abuse using the Services.
          </p>
          <br />
          <h5>2. OUR PROPRIETARY RIGHTS</h5>
          <br />
          <p>
            The Services, Site(s), and all USA Job Solutions content therein and
            all right, title, and interest in and to each of the foregoing,
            including all intellectual property rights therein, are the sole
            property of USA Job Solutions and its licensors. This Agreement
            provides only a limited license to access and use the Services in
            accordance with the terms of this Agreement. Except for the limited
            licenses expressly provided to you in this Agreement, you
            acknowledge and agree that you do not acquire any rights, express or
            implied, in or to the Services. All brand, product, and service
            names and marks used in the Services which ide ntify USA Job
            Solutions are proprietary names and marks of USA Job Solutions.
            Nothing in this Agreement will be deemed to confer on you or any
            third party any license or right with respect to any such name or
            mark. You may not publish, distribute, extract, reuse, or reproduce
            any content from the Site or Services in any form other than in
            accordance with this Agreement. You will not remove, alter, or
            obscure any proprietary notices (including copyright notices) of USA
            Job Solutions or its suppliers in the S ervices or Sites.
          </p>
          <br />
          <h5>3. FEEDBACK</h5>
          <br />
          <p>
            You may, provide us feedback, suggestions, comments, ideas,
            opinions, recommendations for the modification, improvement, or
            enhancement of the Services, or other feedback regarding the
            Services (collectively, “<strong>Feedback</strong>”). If you provide
            us Feedback, you hereby grant USA Job Solutions a royalty-free,
            worldwide, perpetual, irrevocable, transferable, sublicensable, and
            non -exclusive right and license to use such Feedback for any lawful
            purpose, including the development and improvement of the Services.
            To the extent permitted by applicable law, you waive any moral
            rights and irrevocably consent to any acts that would otherwise
            infringe your moral rights, in your Feedback.
          </p>
          <br />
          <h5>4. USER CONTENT</h5>
          <br />
          <p>
            4.1. <strong>Posting User Content; License Grant</strong>. The
            Services may allow you to post and/or provide content that may be
            viewable by other Users, including, but not limited to, Job
            Advertisements, screening questions, screening criteria, company
            information, a Job Seeker’s application information, content of
            messages, resumes/CVs, logos, trademarks, comments, questions, and
            other content or information ( “<strong>User Content</strong>”).
            User Content is the sole responsibility of the person or entity that
            provided the User Content, and you shall be solely responsible for
            the consequences of posting, providing or publishing it. By posting,
            submitting, providing and/or otherwise making available any User
            Content, you expressly grant to USA Job Solutions a royalty-free,
            sublicensable, transferable, non-exclusive, worldwide license to
            use, reproduce, adapt, translate publicly perform, publicly display,
            and otherwise exploit all such User Content, in whole or in part,
            for the purposes of performing the Services, including posting such
            User Content on the Site, and distributing such User Content to Job
            Seekers or sharing with Employers, and to promote USA Job Solutions.
          </p>
          <br />
          <p>
            4.2. <strong>Authority</strong>. You represent and warrant that (i)
            you have the authority, right, and all necessary consents to grant
            the rights described in this Agreement with respect to User Content,
            including to submit, provide, make available or post, via the
            Services, (ii) the use by USA Job Solutions of your User Content as
            contemplated herein is permitted and will not violate any law or
            infringe the rights of any third party, and (iii) your User Content
            and any other information that you provide to us is legal, complete,
            legitimate, truthful and accurate.
          </p>
          <br />
          <p>
            4.3. <strong>Prohibited Types of User Content</strong>. In addition
            to the limitations set forth in Section 1.5 (Limitations), the
            following list is intended to be illustrative of the types of User
            Content that are prohibited, but this is not an exhaustive list: You
            agree not to post or provide User Content that: (i) may create a
            risk of harm to any person or property; (ii) involves the
            transmission of junk or unsolicited mail or other communications,
            ‘phishing’ or ‘scamming’; (iii) provides links to material that is
            illegal or offensive, or targets or solicits personal data from
            anyone under the age of 18; (iv) contains any information or content
            that we deem to be unlawful, harmful, abusive, racially or
            ethnically offensive, defamatory, infringing, invasive of personal
            privacy or publicity rights, harassing, humiliating to other people
            (publicly or otherwise) , libelous, threatening, profane, or
            otherwise objectionable; (v) contains any information or content
            that is illegal (including, without limitation, the disclosure of
            insider information under securities law or of another party's trade
            secrets); (vi) contains any information or content that you do not
            have a right to make available under any law or under contractual or
            fiduciary relationships; (vii) contains any information or content
            that is false, misleading, or otherwise deceptive; or (ix) violates
            the intellectual property rights or rights of privacy of any third
            party.
          </p>
          <br />
          <p>
            4.4. <strong>Job Advertisements</strong>. We will use commercially
            reasonable efforts to maintain Job Advertisements on our Site for
            the duration that you request and to remove Job Advertisements upon
            your written notice. However, you agree, that between USA Job
            Solutions and you, it is your sol e responsibility to comply with
            all applicable laws with respect to posting a Job Advertisement,
            including the duration and location of the Job Advertisement.
          </p>
          <br />
          <p>
            4.5. <strong>No Warranties or Obligations</strong>. You agree that
            USA Job Solutions makes no representations, warranties, promises or
            guarantees regarding any User Content or other content provided or
            generated by third parties, including, without limitation, the
            content of messages, Job Advertisements, and resumés. You further
            agree that USA Job Solutions acts as a passive conduit for the
            distribution, provision, and publication of User Content, and has no
            obligation to screen or verify the accuracy, legality, legitimacy,
            truthfulness, or completeness of User Content, and accordingly, you
            accept that USA Job Solutions is not responsible and has no
            liability for User Content. It is your responsibility to verify the
            quality, accuracy, truthfulness, legality or reliability of User
            Content, including, without limitation, resumes/CVs and Job
            Advertisements and content of messages. Your reliance on any User
            Content is at your own risk. Although, USA Job Solutions has no
            obligation to screen User Content, to the extent that USA Job
            Solutions becomes or is made aware of User Content that may or does
            (i) violate the terms of this Agreement, (ii) violate any law or
            regulation, (iii) violate the rights of third parties, or (iv)
            create actual or potential liability for USA Job Solutions or
            otherwise negatively impact USA Job Solutions, we reserves the right
            to reject and/or remove such User Content, and suspend and/or
            terminate the account associated with such User Content.
          </p>
          <br />
          <p>
            We are not responsible for authenticating Users and therefore it is
            your responsibility to conduct the appropriate due diligence before
            communicating or interacting with other Users, including, without
            limitation, Job Seekers and Employers. You are solely responsible
            for your interactions with other Users. We reserve the right, but
            have no obligation, to monitor disputes between you and other Users.
            We shall have no liability for your interactions with other Users,
            or for any User's action or inaction, and you release us from any
            claim, demands, or damages arising out of or in any way connected
            with such disputes. We shall have no obligation to you to enforce
            this Agreement against any other User.
          </p>
          <br />
          <h5>5. ADDITIONAL TERMS FOR JOB SEEKERS</h5>
          <br />
          <p>
            By applying to a Job Advertisement on the Services, you give us
            permission to store your information on the Services and to share
            your information (including your resume/CV), with the entity that
            posted the Job Advertisement.
          </p>
          <br />
          <p>
            If you ask us to submit a message to an Employer or third party or
            if you apply for a job using the Services, you accept that we do not
            guarantee that the recipient entity will receive, access, view, or
            respond to your requests or Job Advertisements, or that the
            transmission of data will be error-free. Any screener questions
            provided by the entity posting a Job Advertisement are entirely in
            the control of that entity and if you require alternate screener
            questions or application process, you must con tact the applicable
            entity directly.
          </p>
          <br />
          <p>
            Although we request that Employers maintain the confidentiality of
            the Job Seeker information and resumes/CVs they receive through the
            Services, we cannot and do not guarantee that the information you
            provide will be held in confidence or properly secured by the
            applicable recipient. You should conduct your own due diligence on
            potential employers and Job Advertisements that may be of interest
            to you. As we do not authenticate Users or guarantee that a Job
            Advertisement is suitable, legitimate or real.
          </p>
          <br />
          <h5>6. ADDITIONAL TERMS FOR EMPLOYERS</h5>
          <br />
          <p>
            With respect to all Job Advertisements that you submit, make
            available, provide, post or distribute, whether on your own behalf
            or on behalf of a third party, in addition to the other requirements
            in this Agreement, you represent and warrant that (i) the Job
            Advertisement and the content therein (whether owned by you or your
            clients), will comply advertising standards and all applicable laws,
            including, but not limited to, employment and labor laws,
            anti-discrimination laws, and privacy laws, in your jurisdiction and
            the jurisdictions in which the vacancies are located; (ii) you have
            the necessary rights to permit the publication and use of Job
            Advertisements by USA Job Solutions pursuant to this Agreement; and
            (iii) the use, reproduction, distribution or transmission of Job
            Advertisements will not violate any applicable laws or any rights of
            any third parties. You understand and agree that you are solely
            responsible for any liability arising out of publication of Job
            Advertisements or material to which users can link though such Job
            Advertisements.
          </p>
          <br />
          <p>
            Additionally, you agree not to post or promote any Job
            Advertisements that: (a) contain inaccurate, false, or misleading
            information; (b) contain "hidden" keywords or keywords that are
            irrelevant to the job opportunity being presented; (c) sell, promote
            or advertise products or services; (d) endorse a particular
            political party, political agenda, or political position or promote
            a particular religion; ( e) advertise job openings located in
            countries subject to economic sanctions of the United States
            governments, except where permitted by applicable law; (f) require
            the applicant to provide information relating to his/her (i) racial
            or ethnic origin, (ii) political beliefs, (iii) religious beliefs,
            (iv) membership of a trade union, (v) physical or mental health,
            (vi) sexual life, (vii) the commission of criminal offences or
            proceedings, or (viii) age; (g) contain content or links to content
            that exploit people in a sexual, violent or other man ner, or
            solicits personal data from anyone under the age of 13; (h) includes
            any screening requirement or criteria where such requirement or
            criteria is not a bona fide occupational requirement for the role;
            (i) involves any franchise, pyramid scheme, "club membership",
            distributorship, or multi-level marketing opportunity or requires
            recruitment of other members, sub-distributors or sub-agents; (j)
            requires applicants to pay to apply, pay for training, pay for
            training materials, or pay for samples; (k) contain content that
            violates applicable laws; (l) pays commissions only (except for
            postings that make clear that the available job pays commission only
            and clearly describes the product or service that the Job Seeker
            would be selling, in which case such Job Advertisements are
            permissible); (m) requires citizenship or lawful permanent residence
            in a country, unless that is required in order to comply with law,
            regulation, or a federal, state or local government contract; or (n)
            contain links to any site competitive with our Site other than to an
            actual job posting.
          </p>
          <br />
          <p>
            We do not guarantee any responses, or the number of responses, to
            your Job Advertisements, including, without limitation, views,
            clicks, or applications, or that any responses will be from
            individuals suitable for the job vacancy you advertised. We make no
            guarantee as to the quality of candidates that you will receive as a
            result of your Job Advertisement. You are solely responsible for
            interviewing, performing background and reference checks on,
            verifying information provided by, and selecting an appropri ate
            candidate.
          </p>
          <br />
          <p>
            You agree to implement and maintain appropriate physical, technical,
            organizational, and administrative measures to protect the
            confidentiality, safety, and integrity of Job Seeker information
            that you receive, including through the Services. You agree to
            comply with all applicable data protection and privacy laws and
            regulations in applicable jurisdictions particularly as they relate
            to your operations and the residency of the applicable data
            subjects.
          </p>
          <br />
          <h5>7. FEES AND PAYMENT</h5>
          <br />
          <p>The following terms will apply if you are using paid services.</p>
          <br />
          <p>
            7.1. <strong>Billing Policies</strong>. If you use a paid feature of
            the Services, you agree to the pricing and payment terms for the
            applicable Services. We may (i) add new services and products for
            additional fees and charges, at any time in our sole discretion, or
            (ii) amend fees and charges for existing Services, at any time in
            our sole discretion upon notice to you. Any change to the fees for
            paid Services shall become effective in the billing cycle following
            notice of such change to you. All fees are due in advance of the
            provision of the Services, and except as expressly provided herein,
            all fees are non-refundable. All fees shall be paid in U.S. Dollars
            via a method approved by USA Job Solutions (e.g., wire, ACH, credit
            card, etc.) without conditions or reduction for any fees or charges
            applicable to your method of payment (e.g., wire fees).
          </p>
          <br />
          <p>
            7.2. <strong>Payment Information; Taxes</strong>. All information
            that you provide in connection with a payment must be accurate,
            complete, and current. You agree to pay all charges incurred by
            users of your credit card, debit card, or other payment method used
            in connection with a purchase or transaction at the prices in effect
            when such charges are incurred. You will also pay any applicable
            taxes relating to such transactions, excluding taxes based on USA
            Job Solutions’s net income. If at any time we are required by a
            taxing authority to pay any taxes not previously collected from you,
            you will promptly submit such taxes (including applicable penalties
            and interest, if any) to us upon written notice.
          </p>
          <br />
          <p>
            7.3. <strong>Credit and Debit Cards</strong>. In the event that you
            pay for any Services using a credit card or debit card, you
            authorize USA Job Solution(s) to charge your credit or debit card
            (or other acceptable payment method that you have chosen from those
            offered) for the applicable fees, together with any applicable
            sales, use, excise, goods and services tax (GST), consumption or
            other similar tax appli cable to the Services. You represent that
            you are authorized to use such payment method at your company’s
            behalf, and you agree to accept an electronic record of a receipt.
            If at any time your payment method will not accept the charge for
            fees due, you agree that we may suspend or terminate your access to
            the applicable Services and that you will continue to remain liable
            for the full payment for such Services even after your access to the
            Services has been terminated or suspended. By providing any payment
            method, you agree that applicable credit card, debit card and
            billing information may be shared with third parties such a s
            payment processors and credit agencies, and/or collection agencies
            for the purposes of checking your credit, effecting payment,
            collecting payments and late fees if applicable, and for providing
            the applicable Services to you or in order to enforce our agreement
            with you. We may also share this information with law enforcement
            authorities and in response to subpoenas, court orders, and search
            warrants.
          </p>
          <br />
          <h5>8. SUSPENSION OR TERMINATION</h5>
          <br />
          <p>
            We may suspend, limit, condition, or terminate a User’s access to
            the Services or any features thereof, in the event that, we
            reasonably determine that such User has violated this Agreement or
            appears likely to do so. You acknowledge and agree that we may, with
            no liability or penalty, remove any Job Advertisement or other User
            Content, communication or information posted, which in the sole
            judgment of USA Job Solutions, violates or may violate this
            Agreement, applicable laws, rules or regulations, third party terms;
            may adversely affect USA Job Solutions; is inappropriate or false;
            or which may violate or lead to the violation of the rights of, or
            harms or threatens the safety and privacy of any third party. We can
            also terminate your access to the Services and/or this Agreement for
            convenience (not d ue to your actions or omissions) via email
            notification to you and, in the event of such termination for
            convenience, you may receive a pro-rata refund of any pre-paid fees
            pertaining to Services that will not be provided as a result of the
            termination.
          </p>
          <br />
          <h5>9. PRIVACY AND SECURITY</h5>
          <br />
          <p>
            Please refer to our{' '}
            <a style={{ color: '#19918A' }} href="/privacy-policy">
              Privacy Policy
            </a>{' '}
            for information regarding how we collect, process, share and store
            your personal information. If you are an Employer, the following
            applies: to the extent the provision of the Services under this
            Agreement involves the processing of Employer Personal Data (as
            defined by applicable data protection laws) the parties, if required
            by applicable data protection laws, will enter into a mutually
            agreed to data processing addendum to be incorporated into this
            agreement.
          </p>
          <br />
          <p>
            We employ technical, organizational and physical safeguards that are
            designed to protect the personal information we collect. However,
            security risk is inherent in all internet and information
            technologies, and we cannot guarantee the security of your personal
            information.
          </p>
          <br />
          <p>
            You acknowledge and agree that provision of the Services involves,
            and you authorize, our: (i) processing of User Content and Usage
            Data (defined below) in connection with providing the Services, to
            assist you in using the Services, and as described in this
            Agreement; (iii) use of Usage Data in connection with providing,
            analyzing, and improving the Services; and (iii) generation and use
            of de-identified, aggregated, and/or anonymized data that does not
            include any identifying information of, or reasonably permit the
            identification of, you or any individual (including any User). “
            <strong>Usage Data</strong>” means all data collected or generated
            by us in connection with the use of the Services. Usage Data does
            not include User Content.
          </p>
          <br />
          <h5>10. THIRD-PARTY LINKS AND SERVICES</h5>
          <br />
          <p>
            The Services may contain links to third-party websites, advertisers,
            products, services, or other events or activities that are not owned
            or controlled by USA Job Solutions (collectively, "
            <strong>Third-Party(ies)</strong>"). We do not endorse or assume any
            responsibility for any such Third -Party sites, information,
            materials, products, or services. If you access a Third-Party
            website from the Services or use a Third-Party service, you do so at
            your own risk, and you understand that this Agreement and our
            Privacy Policy do not apply to your use of such Third-Party sites or
            services. You expressly relieve USA Job Solutions from any and all
            liability arising from your use of any Third-Party website, service,
            or content.
          </p>
          <br />
          <h5>11. DISCLAIMER OF WARRANTIES</h5>
          <br />
          <p>
            EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS AGREEMENT AND TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, USA JOB SOLUTIONS DOES
            NOT GUARANTEE ANY RESULTS FROM USING THE SERVICES. THE SERVICES ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. USE OF THE SERVICES
            ARE AT YOUR OWN OPTION AND RISK. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, THE SERVICES ARE PROVIDED WITHOUT, AND USA JOB
            SOLUTIONS EXPRESSLY DISCLAIMS ALL WARRANTIES, GUARANTEES, CONDITIONS
            OR TERMS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY
            QUALITY, FITNESS FOR A PURPOSE OR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, OR ANY OTHER IMPLIED WARRANTY, IN EACH CASE
            ARISING FROM OR RELATED TO THIS AGREEMENT OR THE SERVICES.
          </p>
          <br />
          <p>
            WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT OR GUARANTEE THAT
            (I) ANY CONTENT, INCLUDING USER CONTENT, PROVIDED ON OR THROUGH THE
            SERVICES IS ACCURATE, LEGALLY COMPLIANT, UP-TO-DATE, RELIABLE OR
            CORRECT; (II) THE SERVICES WILL MEET YOUR REQUIREMENTS; (III) THE
            SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, OR
            WILL BE UNINTERRUPTED OR SECURE; (IV) ANY DEFECTS OR ERRORS WILL BE
            CORRECTED; (V) THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS, (VI) THAT THE YOUR USE OF THE SERVICES WILL
            SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR
            REGULATIONS; (VII) THAT THE SERVICES WILL RESULT IN A JOB SEEKER
            BEING HIRED OR OBTAINING AN INTERVIEW, POSITIONS BEING FILLED OR
            EMPLOYEES BEING RETAINED, AND IS NOT RESPONSIBLE OR LIABLE FOR ANY
            BUSINESS, EMPLOYMENT, HIRING AND/OR SALARY DECISIONS, FOR WHATEVER
            REASON MADE, MADE BY YOU. ANY CONTENT DOWNLOADED OR OTHERWISE
            OBTAINED THROUGH THE USE OF THE SERVICES IS DOWNLOADED OR AVAILABLE
            AT YOUR OWN OPTION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR
            ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA OR ANY OTHER TYPE
            OF LOSS THAT RESULTS FROM SUCH CONTENT OR YOUR USE OF THE SERVICES.
          </p>
          <br />
          <h5>12. INDEMNITY</h5>
          <br />
          <p>
            You will defend, indemnify and hold harmless USA Job Solutions and
            its respective directors, officers, board members, employees,
            agents, successors and assigns, from and against any and all third
            party claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to all reasonable
            legal fees and expenses) arising from: (i) your use of and access to
            the Services; (ii) your violation of any term of this Agreement,
            including without limitation your breach of any of the
            representations and warranties herein; (iii) your violation of any
            third-party rights, including without limitation any right of
            privacy or intellectual property rights; (iv) your violation of any
            applicable law, rule or regulation; (v) any claims or damages that
            arise as a result of your User Content; (vi) any third-party access
            and use of the Services with your account or log-in information;
            and/or (vii) your intentional or willful misconduct, or negligence.
          </p>
          <br />
          <h5>13. LIMITATION OF LIABILITY</h5>
          <br />
          <p>
            13.1. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
            SHALL USA JOB SOLUTIONS AND ITS RESPECTIVE OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, SUCCESSOR AND ASSIGNS, BE LIABLE TO YOU, WHETHER
            IN CONTRACT, TORT (INCLUDING NEGLIGENCE), FOR BREACH OF STATUTORY
            DUTY, OR OTHERWISE FOR ANY (I) INDIRECT, PUNITIVE, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES OR LOSSES, OR ANY OTHER
            LOSS OR DAMAGE ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT;
            OR (II) LOSS OF PROFITS, GOODWILL, USE, DATA THAT RESULT FROM THE
            USE OF, OR INABILITY TO USE, THE SERVICES, IN EACH CASE, EVEN IF USA
            JOB SOLUTIONS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <br />
          <p>
            13.2. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, USA JOB
            SOLUTIONS ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) USER
            CONTENT, INCLUDING ANY ERRORS, MISTAKES, OR INACCURACIES OF USER
            CONTENT OR RELIANCE THEREON; (II) PERSONAL INJURY OR PROPERTY
            DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR
            USE OF THE SERVICES; (III) UNAUTHORIZED ACCESS TO OR USE OF OUR
            SYSTEMS OR SERVERS AND/OR ANY PERSONAL DATA STORED THEREIN; (IV)
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES;
            (V) BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY; OR THE
            (VI) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY USER OR
            THIRD PARTY.
          </p>
          <br />
          <p>
            13.3. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF USA JOB
            SOLUTIONS, AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND
            AGENTS, SUCCESSORS AND ASSIGNS, TO YOU ARISING OUT OF OR IN
            CONNECTION WITH THIS AGREEMENT EXCEED THE AMOUNT YOU PAID TO USA JOB
            SOLUTIONS DURING THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE
            EVENTS FIRST GIVING RISE TO ANY SUCH LIABILITY, OR IF NO FEES WERE
            PAID, THEN IN NO AMOUNT EXCEEDING $100 USD.
          </p>
          <br />
          <h5>14. CONFIDENTIAL INFORMATION</h5>
          <br />
          <p>
            During your use of the Services, you may obtain access to or be
            provided with Confidential Information of USA Job Solutions. “
            <strong>Confidential Information</strong>” means any proprietary or
            non-public information of USA Job Solutions that is marked or
            identified as confidential or is otherwise disclosed in
            circumstances that would lead a reasonable person to believe such
            information is confidential, regardless of whether such information
            is marked or identified as confidential. You will not use any
            Confidential Information for any purpose not expressly permitted by
            this Agreement and will only disclose Confidential Information to
            your employees or agents who have a need to know such Confidential
            Information for purposes of this Agreement and who are bound by
            confidentiality obligations no less restrictive than those
            obligations under this Agreement. You will keep Confidential
            Information in strict confidence and shall protect Confidential
            Information from unauthorized use, access, or disclosure in the same
            manner that you use to protect your own confidential or proprietary
            information of a similar nature and with no less than reasonable
            care. Upon our written request, you will immediately return or
            destroy any and all materials containing any Confidential
            Information (including all copies, reproductions, and summaries
            thereof) and certify the return or destruction in accordance with
            our request. Breach of this Section 14 (Confidential Information)
            could cause irreparable harm and damage to the USA Job Solutions.
            Thus, in addition to all other remedies available at law or in
            equity, we shall have the right to seek equitable and injunctive
            relief, and to recover the amount of damages (including reasonable
            attorneys’ fees and expenses) incurred in connection with such
            unauthorized use or disclosure.
          </p>
          <br />
          <h5>15. GENERAL</h5>
          <br />
          <p>
            15.1. <strong>Publicity</strong>. You agree that we may use your
            name and logo in our advertising and marketing materials, which
            include customer lists.
          </p>
          <br />
          <p>
            15.2. <strong>Compliance With Law</strong>. Each party will comply
            will all applicable laws in the performance of its obligations
            hereunder.
          </p>
          <br />
          <p>
            15.3. <strong>Electronic Communication</strong>. You understand and
            agree that the Services may require periodic electronic
            communication including password resets, notifications, and other
            critical electronic communications, and that you agree that such
            communications satisfy any requirements that would be met if there
            were in hard copy.
          </p>
          <br />
          <p>
            15.4. <strong>Questions and Notices</strong>. Questions concerning
            the use of the Sites or Services should be directed to{' '}
            <a
              style={{ color: '#19918A' }}
              href="mailto:info@findajobusa.net?bcc=greg@hamparproductions.com"
            >
              info@findajobusa.net
            </a>
            . Notices to USA Job Solutions shall be sent to info@usajobs.net and
            USA Job Solutions LLC, Attn: Legal, [ADDRESS]. We will send notice
            to you at the address submitted by you or to such other address as
            we reasonably determine is an appropriate address for you. Such
            notice will be deemed given upon personal delivery; if sent by
            email, upon a confirmation response; or if sent by overnight
            courier, one (1) day after the date of delivery to the courier.
          </p>
          <br />
          <p>
            15.5. <strong>Governing Law</strong>.
          </p>
          <br />
          <p>
            This Agreement and any disputes arising hereunder shall be governed
            by the internal substantive laws of the State of California, without
            respect to its conflict of laws principles. Each party hereby
            irrevocably and exclusively submits to the process, jurisdiction,
            and venue of the courts located in Los Angeles County, California
            for any suit or other proceeding arising out of or relating to the
            subject matter of this Agreement.
          </p>
          <br />
          <p>
            The Services are controlled and operated by USA Job Solutions from
            within the United States of America. USA Job Solutions makes no
            representations that the Services or the materials available via the
            Services, are appropriate or available for use in locations outside
            of the United States. Those who choose to access the Services from
            other locations do so on their own initiative and are responsible
            for compliance with all applicable laws of United States of America
            as well as local laws (including your own j urisdiction), if and to
            the extent local laws are applicable. You may not use the Services
            if you are a resident of a country embargoed by the United States,
            or are a foreign person or entity blocked or denied by the United
            States governments.
          </p>
          <br />
          <p>
            15.6. <strong>Independent Contractor</strong>. The sole relationship
            between you and USA Job Solutions is that of independent contractors
            and no agency, partnership, joint venture, or employee-employer
            relationship is intended or created by this Agreement.
          </p>
          <br />
          <p>
            15.7. <strong>Severability; Waiver</strong>. If any provision of
            this Agreement is found to be invalid by any court having competent
            jurisdiction, the invalidity of all or part of a provision shall not
            affect the validity of the remaining parts and provisions of this
            Agreement, which shall remain in full force and effect. Neither
            party will be deemed to have waived any of its rights under this
            Agreement by lapse of time or by any statement or representation
            other than by an authorized representative in an explicit written
            waiver. No waiver of any term of this Agreement shall be deemed a
            further or continuing waiver of such term or any other term.
          </p>
          <br />
          <p>
            15.8. <strong>Force Majeure</strong>. USA Job Solutions will not be
            liable under this Agreement by reason of any failure of delay in the
            performance of its obligations under this Agreement as a result of
            any cause which is beyond its reasonable control.
          </p>
          <br />
          <p>
            15.9. <strong>Assignment</strong>. You may not assign or transfer
            your rights or obligations under this Agreement, without the prior
            written consent of USA Job Solutions. USA Job Solutions may freely
            assign or transfer this Agreement without your prior written
            consent.
          </p>
          <br />
          <p>
            15.10. <strong>Entire Agreement</strong>. This Agreement constitutes
            the entire agreement between you and USA Job Solutions and
            supersedes any and all oral or written agreements or understanding
            between the parties with respect to the subject matter of this
            Agreement. This Agreement may only be modified or amended by USA Job
            Solutions. Any and all standard or “form” terms included in,
            associated with or referenced in, a customer purchase order,
            customer ordering document, or customer invoice submission system or
            other portal are hereby rejected (regardless of any electronic or
            online indication of agreement to the same), will not bind the
            parties, will be of no consequence whatsoever in interpreting the
            parties’ legal rights and responsibilities as they pertain to this
            Agreement (including any billing or payment requirements) or the
            Services.
          </p>
          <br />
          <br />
          <h5>Last Updated: August 20, 2024</h5>
          <br />
          <br />
          <h4 style={{ textAlign: 'center' }}>DMCA Notice Procedure</h4>
          <br />
          <p>
            We respect the intellectual property rights of others. It is our
            policy to respond to alleged infringement notices that comply with
            the Digital Millennium Copyright Act of 1998 (“<strong>DMCA</strong>
            ”). Upon receipt of a proper DMCA notice alleging copyright
            infringement, we will take whatever action we deem appropriate,
            including removal of the allegedly infringing materials.
          </p>
          <br />
          <p>
            If you believe that your copyrighted work has been copied in a way
            that constitutes copyright infringement and is accessible via the
            Services, please notify our designated copyright agent as set forth
            below. For your complaint to be valid under the DMCA, you must
            provide the following information in writing:
          </p>
          <br />
          <ul>
            <li>
              Identification of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              Identification of the material that is claimed to be infringing
              and provide a link (where available) to where it is located on the
              Services;
            </li>
            <li>
              Information reasonably sufficient to permit us to contact you,
              such as your address, telephone number, and e-mail address;
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or law; and
            </li>
            <li>
              A statement, made under penalty of perjury, that the above
              information is accurate, and that you are the copyright owner or
              are authorized to act on behalf of the owner
            </li>
            <li>
              An electronic or physical signature of a person authorized to act
              on behalf of the copyright owner;
            </li>
          </ul>
          <br />
          <p>
            Allegedly infringing material will be removed promptly by our
            copyright agent provided that your claim notice includes all of the
            required information. FAILURE TO INCLUDE ALL OF THE ABOVE
            INFORMATION, MAY RESULT IN A DELAY IN THE PROCESSING OF YOUR DMCA
            NOTIFICATION AND MAY RESULT IN YOU HAVING TO REPEAT SOME OR ALL OF
            THE ABOVE PROCESS. WE WILL NOT TAKE RESPONSIVE ACTION TO REMOVE
            CONTENT UNTIL ALL OF THE NECESSARY INFORMATION IS PROVIDED.
          </p>
          <br />
          <p>
            In the event we remove allegedly infringing materials that were
            provided by a third party, we will notify the party responsible for
            posting the materials that we removed or disabled access to the
            materials. We may also provide the responsible party with your
            e-mail address so that they may respond to your allegations.
          </p>
          <br />
          <p>
            We reserve the right to terminate, limit or suspend access to the
            website in the event of repeated infringing activity. If you believe
            repeated infringement is occurring, follow the above instructions to
            contact our Copyright Agent and include sufficient information to
            assist us in identifying evidence of the repeated infringement.
          </p>
          <br />
          <p>
            The above information must be submitted to the following DMCA Agent:
          </p>
          <br />
          <p>
            Attn: DMCA Agent USA Job Solutions LLC; 8772 Enloe Ave. Garden
            Grove, CA. 92844 dmca@findajobusa.net
          </p>
          <br />
          <p>
            UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE
            MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION
            FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT
            COSTS, AND ATTORNEYS' FEES.
          </p>
          <br />
          <p>
            Copyright Counter-Notices. If content you posted on the site was
            removed due to a claim(s) of copyright infringement and you would
            like to dispute that removal, the process for counter-notifications
            is governed by Section 512(g) of the DMCA:
          </p>
          <br />
          <p>
            - To file a counter-notification with us, you must provide a written
            communication that sets forth the items specified below. - Please
            note that under Section 512(f) of the DMCA, any person who knowingly
            materially misrepresents that material or activity was removed or
            disabled by mistake or misidentification may be subject to
            liability. Please also be advised that we enforce a policy that
            provides for the termination in appropriate circumstances of users
            (and removal of content from users) who are infringers. Accordingly,
            if you are not sure whether certain material infringes the
            copyrights of others, we suggest that you first contact an attorney.
          </p>
          <br />
          <p>
            Elements of Counter-Notification. To expedite our ability to process
            your counter-notification, please use the following format
            (including section numbers):
          </p>
          <br />
          <p>
            - Identify the material that has been removed or to which access has
            been disabled, and the location at which the material appeared
            before it was removed or access to it was disabled. - Provide your
            full name, address, telephone number, email address and, if you are
            a registered User, the username of your account. - Provide a
            statement that you consent to the jurisdiction of the courts of the
            state of California, and that you will accept service of process
            from the person who provided notification to USA Job Solutions in
            accordance with the process outlined above or an agent of such
            person. - A statement from you under the penalty of perjury, that
            you in good faith belief that the material was removed or disabled
            as a result of a mistake or misidentification of the material to be
            removed or disabled. - Sign the notice. If You are providing notice
            by email, a scanned physical signature or a valid electronic
            signature will be accepted. Send the communication to the following
            address: Attn: DMCA Agent USA Job Solutions LLC; 8772 Enloe Ave.
            Garden Grove, CA. 92844 dmca@findajobusa.net
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default TermsAndConditionsPage
