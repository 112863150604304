import SearchIcon from '../../assets/icons/SearchIcon'
import { USA_STATES } from '../../constants/defines'
import { createSearchParams, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

const HomeBanner = () => {
  const [state, setState] = useState()
  const navigate = useNavigate()

  return (
    <>
      <div className="col-xl-8 col-lg-12">
        <div className="block-banner text-start">
          <h1 className="heading-banner wow animate__animated animate__fadeInUp">
            The <span className="color-brand-2">Easiest Way</span>
            <br className="d-none d-lg-block" />
            to Find and Post a Job
          </h1>
          <div
            className="banner-description mt-20 wow animate__animated animate__fadeInUp "
            style={{ width: '70%' }}
            data-wow-delay=".1s"
          >
            Each month, job seekers and recruiters turn to FindaJobUSA for their
            job seeking and recruiting needs.
            <br className="d-none d-lg-block" />
          </div>
          <div
            className="form-find mt-40 wow animate__animated animate__fadeIn"
            data-wow-delay=".2s"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const data = new FormData(e.target)
                let searchParams = {}

                if (data.get('search')) {
                  searchParams['search'] = data.get('search')
                }
                if (state) {
                  searchParams['state'] = state
                }
                navigate({
                  pathname: '/jobs',
                  search: createSearchParams(searchParams).toString(),
                })
              }}
            >
              <div className="input-wrapper border-top-2">
                <input
                  className="form-input input-keysearch mr-10 "
                  type="text"
                  name="search"
                  placeholder="Search by title"
                />
              </div>
              <div className="flex align-content-between justify-content-between">
                <div className="box-industry ">
                  <select
                    className="form-input mr-10 input-location"
                    style={{ marginLeft: '-.7rem' }}
                    value={state}
                    type="button"
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">All States</option>
                    {USA_STATES.map((it) => {
                      return (
                        <option key={`homepage-state-select-${it}`} value={it}>
                          {it}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <button
                  className="btn btn-default btn-find font-sm"
                  type={'submit'}
                >
                  <SearchIcon />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeBanner
