import Layout from '../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { withFirebase } from '../firebase'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateUserignedIn } from '../db/user'

const INITIAL_STATE = {
  email: '',
  password: '',
}

const Signin = (props) => {
  const [user, setUser] = useState({ ...INITIAL_STATE })
  const db = props.firebase.getdb()
  const navigate = useNavigate()
  const onSubmit = (e) => {
    e.preventDefault()
    props.firebase
      .doSignInWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        updateUserignedIn(db, userCredential.user.uid).then(() => {
          localStorage.setItem('currentuser', user.email)
          navigate('/profile')
        })
      })
      .catch((error) => {
        toast.warning('Incorrect Email or Password')
        toast.error(error)
      })
  }

  const onGoogleLogin = (e) => {
    e.preventDefault()
    props.firebase.doGoogleLogin()
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setUser({ ...user, [name]: value })
  }

  return (
    <>
      <Layout>
        <section className="pt-100 login-register">
          <div className="container">
            <div className="row login-register-cover">
              <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                <div className="text-center">
                  <p className="font-sm text-brand-2">Welcome back! </p>
                  <h2 className="mt-10 mb-5 text-brand-1">Employer Login</h2>
                  <p className="font-sm text-muted mb-30">
                    Access to all features. No credit card required.
                  </p>
                  <button
                    className="btn social-login hover-up mb-20"
                    onClick={onGoogleLogin}
                  >
                    <img
                      src="assets/imgs/template/icons/icon-google.svg"
                      alt="jobbox"
                    />
                    <strong>Sign in with Google</strong>
                  </button>
                  <div className="divider-text-center">
                    <span>Or continue with</span>
                  </div>
                </div>
                <form
                  className="login-register text-start mt-20"
                  onSubmit={onSubmit}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-1">
                      Username or Email address *
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      required
                      name="email"
                      placeholder="Email"
                      value={user.email}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-4">
                      Password *
                    </label>
                    <input
                      className="form-control"
                      id="input-4"
                      type="password"
                      required
                      name="password"
                      placeholder="************"
                      value={user.password}
                      onChange={onChange}
                    />
                  </div>
                  <div className="login_footer form-group d-flex justify-content-between">
                    <Link to="/reset" className="text-brand-2">
                      Forgot Password
                    </Link>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-brand-2 hover-up w-100"
                      type="submit"
                      name="login"
                    >
                      Sign&nbsp;In
                    </button>
                  </div>
                  <div className="text-muted">
                    Don't Have an Account?
                    <Link className="text-brand-2" to="/register">
                      &nbsp;Sign&nbsp;up
                    </Link>
                  </div>
                </form>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </div>
              <div className="img-1 d-none d-lg-block">
                <img
                  className="shape-1"
                  src="assets/imgs/page/login-register/img-51.svg"
                  alt="JobBox"
                />
              </div>
            </div>
            <div className="row login-register-cover">
              <div className="img-2">
                <img
                  src="assets/imgs/page/login-register/eagle.svg"
                  alt="JobBox"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default withFirebase(Signin)
