const industries = [
  {
    key: 'information-services-or-tech-it',
    value: 'Information Services or Technology (IT)',
    icon: 'marketing.svg',
  },
  {
    key: 'finance',
    value: 'Finance',
    icon: 'finance.svg',
  },
  {
    key: 'human-resources',
    value: 'Human Resources',
    icon: 'human.svg',
  },
  {
    key: 'sales',
    value: 'Sales',
    icon: 'retail.svg',
  },
  {
    key: 'legal',
    value: 'Legal',
    icon: 'content.svg',
  },
  {
    key: 'software-development',
    value: 'Software Development',
    icon: 'customer.svg',
  },
  {
    key: 'marketing',
    value: 'Marketing',
    icon: 'lightning.svg',
  },
  {
    key: 'warehousing-and-storage',
    value: 'Warehousing and Storage',
    icon: 'management.svg',
  },
  {
    key: 'security-analyst',
    value: 'Security Analyst',
    icon: 'security.svg',
  },
  {
    key: 'data-science',
    value: 'Data Science',
    icon: 'research.svg',
  },

  {
    key: 'advertising',
    value: 'Advertising',
    icon: 'customer.svg',
  },

  {
    key: 'agriculture',
    value: 'Agriculture',
    icon: 'finance.svg',
  },
  {
    key: 'analysis',
    value: 'Analysis',
    icon: 'lightning.svg',
  },
  {
    key: 'accounting',
    value: 'Accounting',
    icon: 'retail.svg',
  },
  {
    key: 'biological-science',
    value: 'Biological Science',
    icon: 'human.svg',
  },
  {
    key: 'business-development',
    value: 'Business Development',
    icon: 'management.svg',
  },

  {
    key: 'design',
    value: 'Design',
    icon: 'lightning.svg',
  },
  {
    key: 'engineering',
    value: 'Engineering',
    icon: 'content.svg',
  },

  {
    key: 'graphic-design',
    value: 'Graphic Design',
    icon: 'lightning.svg',
  },
  {
    key: 'management',
    value: 'Management',
    icon: 'marketing.svg',
  },

  {
    key: 'medical-science',
    value: 'Medical Science',
    icon: 'marketing.svg',
  },
  {
    key: 'other',
    value: 'Other',
    icon: 'marketing.svg',
  },
  {
    key: 'product-management',
    value: 'Product Management',
    icon: 'marketing.svg',
  },
  {
    key: 'project-management',
    value: 'Project Management',
    icon: 'marketing.svg',
  },
  {
    key: 'publish-relations',
    value: 'Public Relations',
    icon: 'marketing.svg',
  },
  {
    key: 'quality-assurance',
    value: 'Quality Assurance',
    icon: 'marketing.svg',
  },
  {
    key: 'real-state-or-property-management',
    value: 'Real Estate or Property Management',
    icon: 'marketing.svg',
  },
  {
    key: 'research',
    value: 'Research',
    icon: 'marketing.svg',
  },
  {
    key: 'supply-chain-or-logistics',
    value: 'Supply Chain or Logistics',
    icon: 'marketing.svg',
  },
  {
    key: 'warehousing-and-storage',
    value: 'Warehousing and Storage',
    icon: 'marketing.svg',
  },
  {
    key: 'customer-help',
    value: 'Customer Help',
    icon: 'marketing.svg',
  },
  // {
  //   key: 'retail-and-products',
  //   value: 'Retail & Products',
  //   icon: 'retail.svg',
  // },
  // {
  //   key: 'security-analyst',
  //   value: 'Security Analyst',
  //   icon: 'marketing.svg',
  // },
  // {
  //   key: 'market-research',
  //   value: 'Market Research',
  //   icon: 'content.svg',
  // },
  // {
  //   key: 'content-writer',
  //   value: 'Content Writer',
  //   icon: 'content.svg',
  // },
]
export const getIndustryKeys = () =>
  industries.map((it) => it.key).sort((a, b) => a.localeCompare(b))
export const getIndustryByKey = (key) => industries.find((it) => it.key === key)

export default industries
