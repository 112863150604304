import React from 'react'
import AuthUserContext from './context'
import { withFirebase } from '../firebase'

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!condition(authUser)) {
            window.location = '/login'
          }
        },
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(data) => {
            if (!data || !condition(data.user)) return null

            return (
              <Component
                {...this.props}
                page={window.location.pathname.substring(1)}
                user={data.user}
              />
            )
          }}
        </AuthUserContext.Consumer>
      )
    }
  }

  return withFirebase(WithAuthorization)
}

withAuthorization.contextType = AuthUserContext
export default withAuthorization
